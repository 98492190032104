import React, { useState } from 'react';
import '../styles/authorization-form.scss';
import { getCards, getFilteredSprints, getUsers } from '../components/api';
import { setCards, setIsAuth, setAuthUserData, setFilteredSprints, setUsers, $users } from '../store/cards';
import { Button, TextField } from '@mui/material';
import { domainAuth } from './api';
import { useStore } from 'effector-react';
import Cookies from 'universal-cookie';
import axios from 'axios';

export const AuthorizationForm = () => {
    const users = useStore($users);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const auth = () => {
        domainAuth(login, password).then(auth => {
            setIsAuth(auth.success);
            if (auth.success) {
                const cookies = new Cookies();
                cookies.set('login', auth.login, { path: '/' });
                cookies.set('token', auth.token, { path: '/' });
                axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
                getUsers().then(data => setUsers(data));
                setAuthUserData(users.find(user => user.domain_name && user.domain_name.toLowerCase() === login.toString().toLowerCase()));
            }
        });
    }

    React.useEffect(() => {
        if (users.length) {
            getCards().then(data => {
                setCards(data);
            });
            getFilteredSprints().then(data => {
                setFilteredSprints(data);
            });
        }
    }, [users.length])

    const keyPressed = (event) => {
        if (event.code === "Enter") {
            auth();
        }
    }

    return (
        <div className="authorization-form">
            <form className="authorization-form__window" onKeyDown={keyPressed}>
                <TextField className='input' label="Логин" variant="standard" value={login} onChange={(event) => { setLogin(event.target.value) }} />
                <TextField className='input' label="Пароль" variant="standard" type='password' autoComplete="on" value={password} onChange={(event) => { setPassword(event.target.value) }} />
                <Button onClick={auth} variant="contained">Войти</Button>
            </form>

        </div>
    )
}