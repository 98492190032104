import { createStore, createEvent } from 'effector';

export const $cards = createStore([]);
export const $users = createStore([]);
export const $isAuth = createStore(false);
export const $authUserData = createStore(null);
export const $sprints = createStore([]);
export const $filteredSprints = createStore({last_sprints: [], month_sprints: []});
export const $monthReport = createStore({users_tasks: [], result_sp_count: 0, result_tasks_count: 0});
export const $supportTasks = createStore([]);
export const $statuses = createStore([]);
export const $repositories = createStore([]);
export const $achievements = createStore([]);
export const setCards = createEvent();
export const setUsers = createEvent();
export const setIsAuth = createEvent();
export const setAuthUserData = createEvent();
export const setSprints = createEvent();
export const setFilteredSprints = createEvent();
export const setMonthReport = createEvent();
export const setSupportTasks = createEvent();
export const setStatuses = createEvent();
export const setRepositories = createEvent();
export const setAchievements = createEvent();

$cards
    .on(setCards, (_, payload) => payload);
$users
    .on(setUsers, (_, payload) => payload);
$isAuth
    .on(setIsAuth, (_, payload) => payload);
$authUserData
    .on(setAuthUserData, (_, payload) => payload);
$sprints
    .on(setSprints, (_, payload) => payload);
$filteredSprints
    .on(setFilteredSprints, (_, payload) => payload);
$monthReport
    .on(setMonthReport, (_, payload) => payload);
$supportTasks
    .on(setSupportTasks, (_, payload) => payload);
$statuses
    .on(setStatuses, (_, payload) => payload);
$repositories
    .on(setRepositories, (_, payload) => payload);
$achievements
    .on(setAchievements, (_, payload) => payload);