import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $sprints, setSprints } from '../store/cards';
import { getSprints, deleteSprint } from '../components/api';
import '../styles/admin.scss';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton
} from '@mui/material';
import { SprintEditForm } from '../components/SprintEditForm';
import { Edit, Delete } from '@mui/icons-material';

const date_now = new Date();
const default_sprint_value = {
    id: null,
    number: '',
    month_number: '',
    year: date_now.getFullYear(),
    month: date_now.getMonth() + 1,
    date_start: date_now.toISOString(),
    date_end: date_now.toISOString(),
}

export function SprintPage() {
    const sprints = useStore($sprints);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editedSprint, setEditedSprint] = useState(null);
    const [showDialogDelete, setShowDialogDelete] = useState(false);

    React.useEffect(() => {
        document.title = "TaskMan - Спринты";
    });

    React.useEffect(() => {
        getSprints().then(data => {
            default_sprint_value.number = data.length > 0 ? data[0].number + 1 : 0;
            default_sprint_value.month_number = data[0].month === date_now.getMonth() + 1 ? data[0].month_number + 1 : 1;
            setSprints(data);
        });
    }, [sprints.length]);

    const editSprint = (event, sprint) => {
        setShowEditForm(true);
        setEditedSprint(sprint);
    };

    const deleteSprintButtonClick = (event, sprint) => {
        setEditedSprint(sprint);
        setShowDialogDelete(true);
    };

    const addSprint = () => {
        setShowEditForm(true);
        setEditedSprint(default_sprint_value);
    };

    const handleCloseDialog = (event, dialogResult) => {
        if (dialogResult) {
            deleteSprint({ id: editedSprint.id })
                .then(_ => getSprints().then(data => setSprints(data)));
        }
        setShowDialogDelete(false);
    };

    const formattedData = (sprintDate) => {
        var options = {
            year: 'numeric',
            month: 'long',
            day: "numeric",
            timezone: 'UTC'
        };
        const date = new Date(sprintDate);
        return `${date.toLocaleString("ru", options)}`
    };

    return (
        <div>
            <Button variant="contained" id="add-button" onClick={addSprint}>Добавить</Button>
            <div style={{ width: 1000 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Номер</b></TableCell>
                                <TableCell><b>Номер в месяце</b></TableCell>
                                <TableCell><b>Месяц</b></TableCell>
                                <TableCell><b>Год</b></TableCell>
                                <TableCell><b>Дата начала</b></TableCell>
                                <TableCell><b>Дата окончания</b></TableCell>
                                <TableCell><b>Действия</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sprints.map(s => {
                                return (
                                    <TableRow key={s.id} hover={true}>
                                        <TableCell width={40}>{s.number}</TableCell>
                                        <TableCell width={120}>{s.month_number}</TableCell>
                                        <TableCell width={40}>{s.month}</TableCell>
                                        <TableCell width={40}>{s.year}</TableCell>
                                        <TableCell width={160}>{formattedData(s.date_start)}</TableCell>
                                        <TableCell width={160}>{formattedData(s.date_end)}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={(event) => editSprint(event, s)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                                                <Edit sx={{ fontSize: 20 }} />
                                            </IconButton>
                                            <IconButton onClick={(event) => deleteSprintButtonClick(event, s)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                                                <Delete sx={{ fontSize: 20 }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {showEditForm ? <SprintEditForm setShowEditForm={setShowEditForm} sprint={editedSprint}></SprintEditForm> : null}
            <Dialog
                open={showDialogDelete}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы уверенны, что хотите удалить спринт?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleCloseDialog(event, true)} color="primary">
                        Да
                    </Button>
                    <Button onClick={(event) => handleCloseDialog(event, false)} color="primary" autoFocus>
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}