import axios from "axios";
import { reject } from "q";
import Cookies from 'universal-cookie';

const REST_URL = process.env.REACT_APP_REST_URL;
const cookies = new Cookies();
const token = cookies.get('token');
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export const getCards = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/tasks')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    })
}

export const getUsers = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/users')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getSprints = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/sprints')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getMonthReport = (year, month) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/monthReport/${year}/${month}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getSupportTasks = (year, month) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/getSupportTasks/${year}/${month}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const postSupportTasks = (year, month) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + `/postSupportTasks/${year}/${month}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getTasksForSprintId = (sprint_id) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/sprints/${sprint_id}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getTasksForSprintMonthAndYear = (sprint_year, sprint_month) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/sprints/${sprint_year}/${sprint_month}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const scheduleSprint = (sprint_id) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + `/scheduleSprint/${sprint_id}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getFilteredSprints = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/filteredSprints')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const domainAuth = (login, password) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/auth', { login: login, password: password, service: "bitrix-git-integration" })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getAuth = (login, token) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/getAuth', { login: login, token: token, service: "bitrix-git-integration" })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                resolve({ success: false })
            })
    })
}

export const postAccomplice = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/updateAccomplice', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const setSprintTask = (payload) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + '/updateSprintTask', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const addSprint = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/addSprint', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
}

export const editSprint = (payload) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + '/editSprint', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
}

export const deleteSprint = (payload) => {
    return new Promise((resolve, reject) => {
        axios.delete(REST_URL + '/deleteSprint', {
            data: payload,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
}

export const deleteTask = (payload) => {
    return new Promise((resolve, reject) => {
        axios.delete(REST_URL + '/deleteTask', {
            data: payload,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
}

export const addTask = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/addTask', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}
export const getReportGraphForYear = (year, bitrixUserId) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/getReportGraphForYear/${year}/${bitrixUserId}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const getReportGraphForLastYear = (bitrixUserId) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/getReportGraphForLastYear/${bitrixUserId}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const updateTaskMerge = (taskId, value) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + `/updateTaskMerge/${taskId}/${value}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const updateUserIsHeadOfDepartment = (userId, value) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + `/updateUserHeadOfDepartment/${userId}/${value}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const updateUserActive = (userId, value) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + `/updateUserActive/${userId}/${value}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const updateUserShowInReport = (userId, value) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + `/updateUserShowInReport/${userId}/${value}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const addUser = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/addUser', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const editUser = (payload) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + '/editUser', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const deleteUser = (userId) => {
    return new Promise((resolve, reject) => {
        axios.delete(REST_URL + `/deleteUser/${userId}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const getStatuses = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/statuses')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const previousSprints = (page) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/previousSprints/${page}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const previousMonthSprints = (page) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/previousMonthSprints/${page}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}


export const setUserInTask = (taskId, userId) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + `/setUserInTask/${taskId}/${userId}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getAllMonthSprints = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/allMonthSprints`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const editTask = (payload) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + `/editTask`, payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const getRepositories = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/repositories')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const addRepository = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/addRepository', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const editRepository = (payload) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + '/editRepository', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const deleteRepository = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(REST_URL + `/deleteRepository/${id}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const getAchievements = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/achievements')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const addAchievement = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/addAchievement', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const editAchievement = (payload) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + '/editAchievement', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const deleteAchievement = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(REST_URL + `/deleteAchievement/${id}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const summarizeResults = (year, month) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + `/summarizeResults/${year}/${month}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
}

export const getManuallyAchievements = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + '/manuallyAchievements')
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const setAchievementForUser = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/setAchievementForUser', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const deleteUserAchievement = (payload) => {
    return new Promise((resolve, reject) => {
        axios.delete(REST_URL + '/deleteUserAchievement', { data: payload })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    })
};

export const updateAchievementActive = (id, value) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + `/updateAchievementActive/${id}/${value}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const setViewedTask = (id) => {
    return new Promise((resolve, reject) => {
        axios.put(REST_URL + `/setViewedTask/${id}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const getConfiguration = () => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/getConfiguration`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const updateConfiguration = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/updateConfiguration', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const tasksGlobalSearch = (payload) => {
    return new Promise((resolve, reject) => {
        axios.get(REST_URL + `/tasksSearch/${payload}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};

export const setStatusTask = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(REST_URL + '/setStatusTask', payload)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
};