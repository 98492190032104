import React, { useState } from 'react';
import '../styles/edit-form.scss';
import {
    IconButton,
    FormLabel,
    FormControlLabel,
    Checkbox,
    Button,
    Select,
    MenuItem
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Cookies from 'universal-cookie';

export function GraphSettingsForm(props) {
    const { showGraphSettingsForm, settings, setGraphSetting, graphSettingsData } = props;
    const [showLines, setShowLines] = useState(settings.showLines);
    const [smoothingLines, setSmoothingLines] = useState(settings.smoothingLines);

    const closeForm = () => {
        showGraphSettingsForm(false);
    };

    const saveGraphSettings = () => {
        const cookies = new Cookies();
        graphSettingsData.showLines = showLines;
        graphSettingsData.smoothingLines = smoothingLines;
        cookies.set("graphSettings", graphSettingsData);
        setGraphSetting(graphSettingsData);
        showGraphSettingsForm(false);
    }

    return (
        <div className="edit-form">
            <div className='edit-form__window'>
                <FormLabel className='edit-form__title'><b>Настройки графика</b></FormLabel>
                <FormControlLabel
                    control={<Checkbox checked={showLines} onChange={(event) => setShowLines(event.target.checked)} />}
                    label="Отображать горизонтальные линии" />
                <FormLabel>Сглаживание линий</FormLabel>
                <Select
                    value={smoothingLines}
                    onChange={(event) => setSmoothingLines(event.target.value)}
                >
                    <MenuItem value="linear">Ломанная</MenuItem>
                    <MenuItem value="catmullRom">Сглаженный</MenuItem>
                </Select>
                <Button variant="contained" style={{marginTop: 10}} onClick={saveGraphSettings}>Сохранить</Button>
                <IconButton id='close-button' onClick={closeForm}>
                    <Close />
                </IconButton>
            </div>
        </div>
    )
}