import React, { useState } from 'react';
import { addUser, editUser } from './api'
import '../styles/edit-form.scss';
import {
    TextField,
    Button,
    IconButton,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material';
import { Close } from '@mui/icons-material';

export function UserEditForm(props) {
    const { closeEditForm, user } = props;
    const [bitrixUserId, setBitrixUserId] = useState(user.bitrix_user_id);
    const [gitUserLogin, setGitUserLogin] = useState(user.git_user_login);
    const [userName, setUserName] = useState(user.name);
    const [userSurname, setUserSurname] = useState(user.surname);
    const [userPatronymic, setPatronymic] = useState(user.patronymic);
    const [domainName, setDomainName] = useState(user.domain_name);
    const [isHeadOfDepartment, setIsHeadOfDepartment] = useState(user.is_head_of_department);
    const [showInReport, setShowInReport] = useState(user.show_in_report);
    const [active, setActive] = useState(user.active);

    const addUserButtonClick = () => {
        !user.id
            ? addUser({
                bitrix_user_id: bitrixUserId,
                git_user_login: gitUserLogin,
                name: userName,
                surname: userSurname,
                patronymic: userPatronymic,
                is_head_of_department: isHeadOfDepartment,
                domain_name: domainName,
                active: active,
                show_in_report: showInReport,
            }).then(_ => closeEditForm(true))
            : editUser({
                id: user.id,
                bitrix_user_id: bitrixUserId,
                git_user_login: gitUserLogin,
                name: userName,
                surname: userSurname,
                patronymic: userPatronymic,
                is_head_of_department: isHeadOfDepartment,
                domain_name: domainName,
                active: active,
                show_in_report: showInReport,
            }).then(_ => closeEditForm(true));
    };

    const closeForm = () => {
        closeEditForm(false);
    };

    return (
        <div className="edit-form">
            <div className='edit-form__window'>
                <FormGroup>
                    <TextField className='input' label="Фамилия" variant="standard" value={userSurname} onChange={(event) => setUserSurname(event.target.value)} />
                    <TextField className='input' label="Имя" variant="standard" value={userName} onChange={(event) => setUserName(event.target.value)} />
                    <TextField className='input' label="Отчество" variant="standard" value={userPatronymic} onChange={(event) => setPatronymic(event.target.value)} />
                    <TextField className='input' label="Доменное имя" variant="standard" value={domainName} onChange={(event) => setDomainName(event.target.value)} />
                    <TextField className='input' label="Id Битрикс" variant="standard" value={bitrixUserId} onChange={(event) => setBitrixUserId(event.target.value)} />
                    <TextField className='input' label="Имя GitHub" variant="standard" value={gitUserLogin} onChange={(event) => setGitUserLogin(event.target.value)} />
                    <FormControlLabel
                        control={<Checkbox checked={isHeadOfDepartment} onChange={(event) => setIsHeadOfDepartment(event.target.checked)} />}
                        label="Руководитель"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={showInReport} onChange={(event) => setShowInReport(event.target.checked)} />}
                        label="Выводить в отчёт"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={active} onChange={(event) => setActive(event.target.checked)} />}
                        label="Активен"
                    />
                    <Button variant="contained" id='submit-button' onClick={addUserButtonClick}>{user.id ? 'Изменить' : 'Добавить'}</Button>
                </FormGroup>
                <IconButton id='close-button' onClick={closeForm}>
                    <Close />
                </IconButton>
            </div>
        </div>
    );
}