import { TableCell, TableRow, IconButton } from "@mui/material";
import { useState } from "react";
import { updateAchievementActive } from "./api";
import "../styles/table-combobox.scss";
import { Edit, Delete } from '@mui/icons-material';
import { achievementConditions } from '../shared/enums';


export const AchievementTableRow = (props) => {
    const { achievement, setShowEditForm, setEditedAchievement, setShowDialogDelete } = props;
    const [isActive, setIsActive] = useState(achievement.active);

    const activeChange = (event) => {
        const value = event.target.checked;
        setIsActive(value);
        updateAchievementActive(achievement.id, value);
    };

    const editAchievement = (event, user) => {
        setShowEditForm(true);
        setEditedAchievement(user);
    };

    const deleteAchievementButtonClick = (event, achievement) => {
        setEditedAchievement(achievement);
        setShowDialogDelete(true);
    };

    return (
        <TableRow key={achievement.id} hover={true}>
            <TableCell width={200}>{achievement.name}</TableCell>
            <TableCell width={90}><img className="achievement__icon" src={`data:image/jpeg;base64,${achievement.icon}`} alt={achievement.name} /></TableCell>
            <TableCell width={50}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            background: achievement.color,
                            marginRight: 5,
                            border: "1px #767676 solid",
                            borderRadius: "3px"
                        }}></div>
                    {achievement.color}
                </div>
            </TableCell>
            <TableCell width={160}>{achievementConditions[achievement.condition]}</TableCell>
            <TableCell width={50} align="center">
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={(event) => activeChange(event)}
                />
            </TableCell>
            <TableCell>
                <IconButton onClick={(event) => editAchievement(event, achievement)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                    <Edit sx={{ fontSize: 20 }} />
                </IconButton>
                <IconButton onClick={(event) => deleteAchievementButtonClick(event, achievement)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                    <Delete sx={{ fontSize: 20 }} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}