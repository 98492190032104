import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { editTask } from './api';
import { $statuses, $users, $sprints, $authUserData, $repositories } from '../store/cards';
import '../styles/edit-form.scss';
import {
    TextField,
    Button,
    IconButton,
    FormGroup,
    Select,
    MenuItem,
    FormHelperText,
    FormLabel,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { Close } from '@mui/icons-material';

export function TaskEditForm(props) {
    const { showEditTask, task } = props;
    const statuses = useStore($statuses);
    const users = useStore($users);
    const sprints = useStore($sprints);
    const repositories = useStore($repositories);
    const authUserData = useStore($authUserData);
    const [issue, setIssue] = useState(task ? task.issue_id : "");
    const [pr, setPR] = useState(task ? task.pr_id : "");
    const [sprint, setSprint] = useState(task ? task.sprint.id : 0);
    const [reviewer, setReviewer] = useState(task ? task.accomplice_id : 0);
    const [status, setStatus] = useState(task ? task.status.id : null);
    const [repository, setRepository] = useState(task && task.repository ? task.repository.id : 0);
    const [isMerged, setIsMerged] = useState(task.is_merged);
    const EditTaskButtonClick = () => {
        task.issue_id = issue;
        task.pr_id = pr;
        task.sprint = sprints.find(s => s.id === sprint);
        task.accomplice_id = reviewer;
        task.status = statuses.find(s => s.id === status);
        task.repository = repositories.find(r => r.id === repository);
        task.is_merged = isMerged;
        editTask(task).then(() => {
            showEditTask(false);
        });
    };

    const closeForm = () => {
        showEditTask(false);
    };

    const formattedData = (month, year) => {
        var options = {
            year: 'numeric',
            month: 'long',
            timezone: 'UTC'
        };
        const date = new Date(`${year}-${month}-01`);
        return `${date.toLocaleString("ru", options)}`
    };

    return (
        <div className="edit-form">
            <div className='edit-form__window'>
                <FormGroup>
                    <FormLabel className='edit-form__title'><b>{`${task.task_id} ${task.title}`}</b></FormLabel>
                    <FormHelperText>Issue</FormHelperText>
                    <TextField className='input' variant="standard" value={issue} onChange={(event) => setIssue(event.target.value)} />
                    <FormHelperText>PR</FormHelperText>
                    <TextField className='input' variant="standard" value={pr} onChange={(event) => setPR(event.target.value)} />
                    {authUserData && authUserData.is_head_of_department
                        ? <FormGroup>
                            <FormHelperText>Спринт</FormHelperText>
                            <Select
                                value={sprint}
                                onChange={(event) => setSprint(event.target.value)}
                                displayEmpty
                            >
                                {sprints.filter(sprint => {
                                    let previousDate = new Date(task.sprint.year, task.sprint.month - 2, 1);
                                    let sprintDate = new Date(sprint.year, sprint.month - 1, 1);
                                    return sprintDate >= previousDate;
                                }).map(sprint =>
                                    <MenuItem value={sprint.id} key={sprint.id}>
                                        {formattedData(sprint.month, sprint.year) + ` (${sprint.month_number})`}
                                    </MenuItem>
                                )}
                            </Select>
                            <FormHelperText>Ревьювер</FormHelperText>
                            <Select
                                value={reviewer}
                                onChange={(event) => setReviewer(event.target.value)}
                                displayEmpty
                            >
                                <MenuItem value={0} key={0}>Ревьювер не выбран</MenuItem>
                                {users.filter(u => u.bitrix_user_id !== task.responsible_id.toString() && u.active).map(user =>
                                    <MenuItem value={user.bitrix_user_id} key={user.bitrix_user_id}>{`${user.surname} ${user.name}`}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>Статус</FormHelperText>
                            <Select
                                value={status}
                                onChange={(event) => setStatus(event.target.value)}
                            >
                                {statuses.map(status =>
                                    <MenuItem value={status.id} key={status.id}>{status.name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>Репозиторий</FormHelperText>
                            <Select
                                value={repository}
                                onChange={(event) => setRepository(event.target.value)}
                            >
                                <MenuItem value={0} key={0}>Не выбран</MenuItem>
                                {repositories.map(r =>
                                    <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>
                                )}
                            </Select>
                            <FormControlLabel
                                control={<Checkbox checked={isMerged} onChange={(event) => setIsMerged(event.target.checked)} />}
                                label="PR слит"
                            />
                        </FormGroup>
                        : null
                    }
                    <Button variant="contained" id='submit-button' onClick={EditTaskButtonClick}>Сохранить</Button>
                </FormGroup>
                <IconButton id='close-button' onClick={closeForm}>
                    <Close />
                </IconButton>
            </div>
        </div>
    );
}