import React, { useState } from 'react';

import { addRepository, editRepository } from './api'
import '../styles/edit-form.scss';
import {
    TextField,
    Button,
    IconButton,
    FormHelperText
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ChromePicker } from 'react-color';

export function RepositoryEditForm(props) {
    const { setShowEditForm, repository, closeCallback } = props;
    const [bitrixCode, setBitrixCode] = useState(repository.bitrix_code ?? 0);
    const [name, setName] = useState(repository.name ?? '');
    const [projectId, setProjectId] = useState(repository.project_id??'');
    const [color, setColor] = useState(repository.color??'');

    const addRepositoryButtonClick = () => {
        !repository.id
            ? addRepository({
                bitrix_code: bitrixCode,
                name: name,
                project_id: projectId !== "" ? projectId : null,
                color: color
            })
            : editRepository({
                id: repository.id,
                bitrix_code: bitrixCode,
                name: name,
                project_id: projectId !== "" ? projectId : null,
                color: color
            })
        setShowEditForm(false);
        closeCallback();
    };

    const closeForm = () => {
        setShowEditForm(false);
    };

    return (
        <div className="edit-form">
            <div className='edit-form__window'>
                <TextField className='input' type="number" label="Код в битрикс" variant="standard" value={bitrixCode} onChange={(event) => { setBitrixCode(event.target.value) }} />
                <TextField className='input' label="Наименование" variant="standard" value={name} onChange={(event) => { setName(event.target.value) }} />
                <TextField className='input' type="number" label="ID проекта на GitHub" variant="standard" value={projectId} onChange={(event) => { setProjectId(event.target.value) }} />
                <FormHelperText>Цвет</FormHelperText>
                <div style={{width: '100%', height:  30, background: color + '4d', margin: '10px 0px'}}></div>
                <ChromePicker 
                    color={color}
                    onChangeComplete={(color) => setColor(color.hex)}
                    disableAlpha={true}
                />
                {/* <TextField className='input' label="Цвет" variant="standard" value={color} onChange={(event) => { setColor(event.target.value) }} /> */}
                <Button variant="contained" id='submit-button' onClick={addRepositoryButtonClick}>{repository.id ? 'Изменить' : 'Добавить'}</Button>
                <IconButton id='close-button' onClick={closeForm}>
                    <Close />
                </IconButton>
            </div>
        </div>
    );
}