import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { Main, Admin, ReportPage } from './pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  getUsers,
  getAuth,
  getCards,
  getFilteredSprints,
  getSprints,
  getStatuses,
  getRepositories
} from './components/api';
import {
  setUsers,
  $isAuth,
  setIsAuth,
  $users,
  setAuthUserData,
  setCards,
  setFilteredSprints,
  setSprints,
  setStatuses,
  setRepositories
} from './store/cards';
import { AuthorizationForm, ProgressLoader } from './components';
import Cookies from 'universal-cookie';

function App() {
  const users = useStore($users);
  const isAuth = useStore($isAuth);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tasksLoaded, setTasksLoaded] = useState(false);

  React.useEffect(() => {
    if (!isAuth) {
      const cookies = new Cookies();
      const login = cookies.get('login');
      const token = cookies.get('token');

      if (login) {
        getAuth(login, token).then(data => {
          if (data.success) {
            getUsers().then(data => setUsers(data));
            setIsAuth(data.success);
          }
          setIsLoaded(true);
        });
      }
      else {
        setTimeout(() => {
          setIsLoaded(true);
        }, 1000)

      }
    }

  }, [users.length, isAuth]);

  React.useEffect(() => {
    const cookies = new Cookies();
    const login = cookies.get('login');

    if (users.length) {
      setAuthUserData(users.find(user => user.domain_name && user.domain_name.toLowerCase() === login.toString().toLowerCase()));
      getCards().then(data => { 
        setCards(data);
        setTasksLoaded(true);
      });
      getFilteredSprints().then(data => {
        setFilteredSprints(data);
      });
      getSprints().then(data => setSprints(data));
      getStatuses().then(data => setStatuses(data));
      getRepositories().then(data => setRepositories(data));
    }
  }, [users.length]);

  return (
    <div className="App">
      {!tasksLoaded && isLoaded && isAuth === true ? <ProgressLoader/> : null}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main setTasksLoaded={setTasksLoaded} />} />
          <Route path="/reportPage" element={<ReportPage />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
      {isLoaded && isAuth === false ? <AuthorizationForm></AuthorizationForm> : null}
    </div>
  );
}

export default App;
