import React, { useState } from 'react';
import { addSprint, editSprint, getSprints } from './api';
import { setSprints } from '../store/cards';
import '../styles/edit-form.scss';
import {
    TextField,
    Button,
    IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';

export function SprintEditForm(props) {
    const { setShowEditForm, sprint } = props;
    const [number, setNumber] = useState(sprint.number??0);
    const [monthNumber, setMonthNumber] = useState(sprint.month_number??0);
    const [monthAndYear, setMonthAndYear] = useState(sprint.year + '-' + (sprint.month > 9 ? sprint.month : '0' + sprint.month));
    const [dateStart, setDateStart] = useState(sprint.date_start.split('T')[0]);
    const [dateEnd, setDateEnd] = useState(sprint.date_end.split('T')[0]);

    const addSprintButtonClick = () => {
        !sprint.id
            ? addSprint({
                number: number,
                month_number: monthNumber,
                month: monthAndYear.split('-')[1],
                year: monthAndYear.split('-')[0],
                date_start: dateStart,
                date_end: dateEnd
            }).then(_ => getSprints().then(data => setSprints(data)))
            : editSprint({
                id: sprint.id,
                number: number,
                month_number: monthNumber,
                month: monthAndYear.split('-')[1],
                year: monthAndYear.split('-')[0],
                date_start: dateStart,
                date_end: dateEnd
            }).then(_ => getSprints().then(data => setSprints(data)))
        setShowEditForm(false);
    };

    const closeForm = () => {
        setShowEditForm(false);
    };

    return (
        <div className="edit-form">
            <div className='edit-form__window'>
                <TextField className='input' label="Номер спринта" variant="standard" value={number} onChange={(event) => { setNumber(event.target.value) }} />
                <TextField className='input' label="Номер в месяце" variant="standard" value={monthNumber} onChange={(event) => { setMonthNumber(event.target.value) }} />
                <TextField className='input' type="month" label="Месяц и год" variant="standard" value={monthAndYear} onChange={(event) => { setMonthAndYear(event.target.value) }} />
                <TextField className='input' type='date' label="Дата начала" variant="standard" value={dateStart} onChange={(event) => { setDateStart(event.target.value) }} />
                <TextField className='input' type='date' label="Дата окончания" variant="standard" value={dateEnd} onChange={(event) => { setDateEnd(event.target.value) }} />
                <Button variant="contained" id='submit-button' onClick={addSprintButtonClick}>{sprint.id ? 'Изменить' : 'Добавить'}</Button>
                <IconButton id='close-button' onClick={closeForm}>
                    <Close />
                </IconButton>
            </div>
        </div>
    );
}