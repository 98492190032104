import React from 'react';
import '../styles/table-combobox.scss'
import { $users, setUsers} from '../store/cards';
import { useStore } from 'effector-react';
import { useState } from 'react';
import { postAccomplice, getUsers } from './api';
import {
    Select,
    MenuItem
} from '@mui/material';
export const AccompliceCombobox = (props) => {
    const { task } = props;
    const [value, setValue] = useState(task.accomplice_id);
    const users = useStore($users);

    const selectChanged = (event) => {
        setValue(event.target.value);
        task.accomplice_id = event.target.value;
        postAccomplice({ task_id: task.task_id, user_id: event.target.value }).then(() => {
            getUsers().then(data => setUsers(data));
        });
    }

    return (
        <Select className='table-combobox' onChange={selectChanged} value={value}>
            <MenuItem value={0} key={0}></MenuItem>
            {users.filter(user => user.active && task.responsible_id.toString() !== user.bitrix_user_id).map((user) => {
                return (<MenuItem value={user.bitrix_user_id} key={user.id}>{`${user.surname} ${user.name}`} {user.id > 0 ? `(${user.review_tasks_count})` : null}</MenuItem>)
            })}
        </Select>
    )
}