import React, { useState } from 'react';
import { useStore } from 'effector-react';
import {
    IconButton
} from '@mui/material';
import { Home, Settings } from '@material-ui/icons';
import '../styles/month-report.scss';
import '../styles/header.scss';
import { MonthReportPage } from './MonthReportPage';
import { SupportMonthReportPage } from './SupportMonthReportPage';
import { $authUserData } from '../store/cards';

const pages = [
    "Месячный отчёт",
    "Задачи поддержки",
]

export function ReportPage() {
    const [selectedPage, setSelectedPage] = useState(0);
    const authUserData = useStore($authUserData);

    return (
        <div className='month-report'>
            <div className='header'>
                <div className='icons-block'>
                    <IconButton onClick={() => window.location.href = "/"}>
                        <Home />
                    </IconButton>
                    {
                        authUserData && authUserData.is_head_of_department
                            ? <IconButton onClick={() => window.location.href = "/admin"}>
                                <Settings />
                            </IconButton>
                            : null
                    }
                </div>
                {
                    pages.map((page, index) =>
                        <p
                            key={index}
                            className='menu-item'
                            style={selectedPage === index
                                ? { color: "#1976d2", borderBottom: "2px #1976d2 solid" }
                                : { color: null, borderBottom: null }}
                            onClick={() => setSelectedPage(index)}
                        >
                            {page}
                        </p>
                    )
                }
            </div>
            {selectedPage === 0 ? <MonthReportPage /> : null}
            {selectedPage === 1 ? <SupportMonthReportPage /> : null}
        </div>

    );

}