import {
    TableCell,
    TableRow,
} from '@mui/material';
export const CardTableHeader = (props) => {
    const { cards, title } = props;

    const getSPCount = (cards) => {
        let sum = 0;
        cards.forEach((card) => sum += card.sp_count !== null ? (card.sp_count === 0 ? 0.5 : card.sp_count) : 0);
        return sum;
    }

    return (
        cards.length
            ? <TableRow>
                <TableCell colSpan={12}>
                    <b>{title} ({cards.length} / {getSPCount(cards)}SP)</b>
                </TableCell>
            </TableRow>
            : null
    )
}