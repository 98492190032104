import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $authUserData } from '../store/cards';
import '../styles/admin.scss';
import '../styles/header.scss';
import { GeneralSettingsPage, SprintPage, UsersPage, RepositoriesPage, AchievementsPage } from './';
import {
    IconButton,
    Toolbar,
    Button,
    AppBar,
    Box
} from '@mui/material';
import { Home, Analytics } from '@mui/icons-material';

const pages = [
    "Общие настройки",
    "Спринты",
    "Пользователи",
    "Репозитории",
    "Достижения"
]

export function Admin() {
    const authUserData = useStore($authUserData);
    const [selectedPage, setSelectedPage] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    React.useEffect(() => {
        if (authUserData) {
            setIsLoaded(true);
        }
    }, [authUserData]);

    return isLoaded && !authUserData.is_head_of_department
        ? window.location.href = "/"
        : (
            <div className='admin'>
                <div className='header'>
                    <div className='icons-block'>
                        <IconButton onClick={() => window.location.href = "/"}>
                            <Home />
                        </IconButton>
                        <IconButton onClick={() => window.location.href = "/reportPage"}>
                            <Analytics />
                        </IconButton>
                    </div>
                    {
                        pages.map((page, index) =>
                            <p
                                key={index}
                                className='menu-item'
                                style={selectedPage === index
                                    ? { color: "#1976d2", borderBottom: "2px #1976d2 solid" }
                                    : { color: null, borderBottom: null }}
                                onClick={() => setSelectedPage(index)}
                            >
                                {page}
                            </p>
                        )
                    }
                </div>
                {selectedPage === 0 ? <GeneralSettingsPage /> : null}
                {selectedPage === 1 ? <SprintPage /> : null}
                {selectedPage === 2 ? <UsersPage /> : null}
                {selectedPage === 3 ? <RepositoriesPage /> : null}
                {selectedPage === 4 ? <AchievementsPage /> : null}
            </div>
        );

}