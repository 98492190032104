import { TableCell, TableRow, IconButton } from "@mui/material";
import { useState } from "react";
import { updateUserActive, updateUserIsHeadOfDepartment, updateUserShowInReport } from "./api";
import "../styles/table-combobox.scss";
import { Edit, Delete } from '@mui/icons-material';


export const UserTableRow = (props) => {
    const { user, setShowEditForm, setEditedUser, setShowDialogDelete } = props;
    const [isHeadOfDepartment, setIsHeadOfDepartment] = useState(user.is_head_of_department);
    const [isActive, setIsActive] = useState(user.active);
    const [showInReport, setShowInReport] = useState(user.show_in_report);

    const activeChange = (event) => {
        const value = event.target.checked;
        setIsActive(value);
        updateUserActive(user.id, value);
    };

    const headOfDepartmentChange = (event) => {
        const value = event.target.checked;
        user.active = value;
        setIsHeadOfDepartment(value);
        updateUserIsHeadOfDepartment(user.id, value);
    };

    const showInReportChanged = (event) => {
        const value = event.target.checked;
        setShowInReport(value);
        updateUserShowInReport(user.id, value);
    };

    const editUser = (event, user) => {
        setShowEditForm(true);
        setEditedUser(user);
    };

    const deleteUserButtonClick = (event, user) => {
        setEditedUser(user);
        setShowDialogDelete(true);
    };

    return (
        <TableRow
            key={user.id}
            hover={true}
            style={{ background: !isActive ? "#d0d0d0" : null }}
        >
            <TableCell>{`${user.surname} ${user.name}`}</TableCell>
            <TableCell>{user.domain_name}</TableCell>
            <TableCell>{user.git_user_login}</TableCell>
            <TableCell align="center">{user.bitrix_user_id}</TableCell>
            <TableCell align="center">
                <input type="checkbox" checked={isHeadOfDepartment} onChange={headOfDepartmentChange} />
            </TableCell>
            <TableCell align="center">
                <input type="checkbox" checked={showInReport} onChange={showInReportChanged} />
            </TableCell>
            <TableCell align="center">
                <input type="checkbox" checked={isActive} onChange={activeChange} />
            </TableCell>
            <TableCell align="center">
                <div style={{ display: 'flex' }}>
                    <IconButton onClick={(event) => editUser(event, user)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                        <Edit sx={{ fontSize: 20 }} />
                    </IconButton>
                    <IconButton onClick={(event) => deleteUserButtonClick(event, user)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                        <Delete sx={{ fontSize: 20 }} />
                    </IconButton>
                </div>
            </TableCell>
        </TableRow>
    )
}