import React, { useState } from 'react';
import { addAchievement, editAchievement } from './api'
import '../styles/edit-form.scss';
import {
    TextField,
    Button,
    IconButton,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormHelperText
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ChromePicker } from 'react-color';
import { achievementConditions } from '../shared/enums';

export function AchievementEditForm(props) {
    const { setShowEditForm, achievement, closeCallback } = props;
    const [name, setName] = useState(achievement.name ?? '');
    const [icon, setIcon] = useState('');
    const [color, setColor] = useState(achievement.color ?? '');
    const [condition, setCondition] = useState(achievement.condition ?? 0);
    const [active, setActive] = useState(achievement.active ?? true);
    const [selectedFile, setSelectedFile] = useState(null);

    const addRepositoryButtonClick = () => {
        let formData = new FormData();
        if (!achievement.id) {
            formData.append("name", name);
            formData.append("files", selectedFile);
            formData.append("color", color);
            formData.append("condition", condition);
            formData.append("active", active);
            addAchievement(formData);
        }
        else {
            formData.append("id", achievement.id)
            formData.append("name", name);
            formData.append("files", selectedFile);
            formData.append("color", color);
            formData.append("condition", condition);
            formData.append("active", active);
            editAchievement(formData);
        }
        setShowEditForm(false);
        closeCallback();
    };

    const closeForm = () => {
        setShowEditForm(false);
    };

    const changeSelectedFile = (event) => {
        setIcon(event.target.value);
        setSelectedFile(event.target.files[0]);
    }

    return (
        <div className="edit-form">
            <div className='edit-form__window'>
                <TextField className='input' label="Наименование" variant="standard" value={name} onChange={(event) => { setName(event.target.value) }} />
                <TextField className='input' type="file" label="Изображение" variant="standard" value={icon} onChange={changeSelectedFile} />
                <FormHelperText>Цвет</FormHelperText>
                <ChromePicker 
                    color={color}
                    onChangeComplete={(color) => setColor(color.hex)}
                />
                <FormHelperText>Условие</FormHelperText>
                <Select
                    value={condition}
                    onChange={(event) => setCondition(event.target.value)}
                    displayEmpty
                >
                    {
                        achievementConditions.map((condition, index) => {
                            return (<MenuItem value={index} key={index}>{condition}</MenuItem>)
                        })
                    }
                </Select>
                <FormControlLabel
                    control={<Checkbox checked={active} onChange={(event) => setActive(event.target.checked)} />}
                    label="Активно" />
                <Button variant="contained" id='submit-button' onClick={addRepositoryButtonClick}>{achievement.id ? 'Изменить' : 'Добавить'}</Button>
                <IconButton id='close-button' onClick={closeForm}>
                    <Close />
                </IconButton>
            </div>
        </div>
    );
}